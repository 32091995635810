import React from "react"
import styled, { css } from "styled-components"
import { Link } from 'gatsby'

import FlexboxRow from 'src/components/FlexboxRow'
import HorizontalLine from 'src/components/HorizontalLine'


const Wrapper = styled(FlexboxRow).attrs({
  className: "MenuOverlayWrapper",
})`
  align-items: flex-start
  ;
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 0.96;

  padding-top: 34vh;
  padding-left: 13vw;

  background-color: #0f1726;
  z-index: 1;

  color: #fff;

  /* TODO: ADD FADE OUT HERE AS WELL */
  animation: 250ms ease-in "fade-in";

  .Left {
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 900;
    margin-right: 20vw;
  }

  .Right {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;

    div {
      text-transform: none;
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
`


const StyledNavLink = styled(FlexboxRow).attrs({
  className: "NavLink",
})`
  align-items: center;
  width: 26vw;

  /* Prevents words from wrapping inside flexbox */
  white-space: nowrap;
  padding-left: calc(3vw + 1rem);

  ${({ isSelected }) =>
    isSelected &&
    css`
      padding-left: 0;
    `}

  span {
    margin-right: 1rem;
  }
`

function NavLink({ isSelected, to, children }) {
  return (
    <Link to={to}>
      <StyledNavLink isSelected={isSelected}>
        {isSelected && <HorizontalLine />}
        <span>{children}</span>
        {isSelected && <HorizontalLine />}
      </StyledNavLink>
    </Link>
  )
}

export default function MenuOverlay({ selectedRoute }) {
  return (
    <Wrapper>
      <div className="Left">
        <NavLink to="/" isSelected={selectedRoute === '/'}>Home</NavLink>
        <NavLink to="/about" isSelected={selectedRoute === '/about'}>About</NavLink>
        {/* <NavLink to="/products" isSelected={selectedRoute === '/products'}>Products</NavLink> */}
        {/* <NavLink to="/blog" isSelected={selectedRoute === '/blog'}>Blog</NavLink> */}
      </div>

      <div className="Right">
        Automation.co
        <div>
          1201 Orange Street, Suite 600 Wilmington,
          Delaware 19801
        </div>
        <div>
          ☎️ 410 603 5562
        </div>
      </div>
    </Wrapper>
  )
}

