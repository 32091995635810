import React from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'

import { Link } from 'gatsby'

import HAMBURGER from 'src/assets/img/hamburger.svg'
import AUTOMATION_LOGO from 'src/assets/img/automation-logo.svg'

import CLOSE_ICON from "src/assets/img/close-icon.svg"

const Wrapper = styled.div.attrs({
  className: "MenuWrapper",
})`
  height: 10rem;
  width: 5rem;

  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  div {
    height: 50%;
  }

  .SquareLogo {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--deep-blue);

    img {
      width: 3.5rem;
    }
  }

  .SquareHamburger {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-blue);

    img {
      width: 1.5rem;
      height: 1.25rem;
    }
  }
`

export default function Menu({
  isOpen = false,
  onClickToggle = noop
}) {
  return (
    <Wrapper>
      <Link to="/">
        <div className="SquareLogo">
          <img src={AUTOMATION_LOGO} alt="automation.co logo" />
        </div>
      </Link>
      <div className="SquareHamburger" onClick={onClickToggle}>
        {isOpen ? (
          <img src={CLOSE_ICON} alt="x close icon" role="button" />
        ) : (
          <img src={HAMBURGER} alt="hamburger" role="button" />
        )}
      </div>
    </Wrapper>
  )
}
