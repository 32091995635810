import styled from 'styled-components'

export default styled.div.attrs({
  className: "HorizontalLine",
})`
  width: 3vw;
  height: 1px;
  border: 1px solid #fff;

  :first-of-type {
    margin-right: 1rem;
  }

  :last-of-type {
    width: 100%;
    max-width: 10vw;
  }
`
