import React, { useState } from 'react'
import styled from 'styled-components'

import Menu from 'src/components/Menu'
import MenuOverlay from "src/components/MenuOverlay"

const StyledLayout = styled.div`

`

export default ({
  children,
  location,
  history,
  match
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function toggleMenuOpen() {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <StyledLayout>
      <Menu
        onClickToggle={toggleMenuOpen}
        isOpen={isMenuOpen}
      />

      {isMenuOpen && <MenuOverlay selectedRoute={location.pathname} />}
      {children}

    </StyledLayout>
  )
}
